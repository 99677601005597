import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import Ocm from "../components/register/ocm";
import Ogb from "../components/register/ogb";
import Home from '../components/home';
import {service} from "../utils/constant";


const OpenRoute = () => {
    if(service==="ocm") 
    {        
        return (                
            <Routes>
                <Route exact path='/' element={<Home/>} /> 
                <Route path='/home' element={<Home/>} />             
                <Route path='/register' element={<Ocm/>}  />
                <Route path='/login' element={<Ocm/>} />
                <Route path='*' element={<Navigate to="/register" />} /> 
            </Routes>
        )
    }
    else if(service==="ogb") 
    {        
        return (                
            <Routes>
                <Route exact path='/' element={<Home/>} /> 
                <Route path='/home' element={<Home/>} />             
                <Route path='/register' element={<Ogb/>}  />
                <Route path='/login' element={<Ogb/>} />
                <Route path='*' element={<Navigate to="/register" />} /> 
            </Routes>
        )
    }

};

export default OpenRoute;