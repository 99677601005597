import { useCallback, useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Autoplay } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import "./css/swiperEl.css"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const SwiperE2 = (props) => {
  const { t } = useTranslation("global")
  let { category_name, games } = props.gameData
  games = games.slice(0, 10)
  const navigate = useNavigate()
  const sliderRef = useRef(null)
  const handlePrev = useCallback(() => {
    // if (!sliderRef.current) return;
    // sliderRef.current.swiper.slidePrev();
  }, [])

  const handleNext = useCallback(() => {
    // if (!sliderRef.current) return;
    // sliderRef.current.swiper.slideNext();
  }, [])

  const handleViewAll = (category_name) => {
    navigate(`/allGames/${category_name}`)
  }

  return (
    <>
      <div className="swiper_element" id={`${category_name}_swiper`}>
        <div className="swiper_top_flex">
          <h4>{t(`category.${category_name}`)}</h4>
          <button onClick={() => handleViewAll(category_name)}>
            {t("portalText.view")}
          </button>
        </div>
        <Swiper
          ref={sliderRef}
          modules={[Navigation, Autoplay]}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          breakpoints={{
            320: {
              slidesPerView: 1.5,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 1.5,
              spaceBetween: 24,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          navigation={{
            nextEl: `.swiper-next-arrow${category_name}`,
            prevEl: `.swiper-prev-arrow${category_name}`,
          }}
        >
          {games &&
            games.map((current) => {
              return (
                <SwiperSlide key={current?.name}>
                  <div className="swiper_card">
                    {/* <div class="swiper-image"> */}
                    <img src={current?.image} alt={current?.name} />
                    {/* </div> */}
                    <a href={current?.path}>
                      <div className="swiper_card_title">
                        <div className="swiper_card_text">
                          {t(`${category_name}.${current.name}`)}
                        </div>
                        <div className="swiper_card_arrow">
                          {category_name !== "Android" ? (
                            <i className="fas fa-arrow-right"></i>
                          ) : (
                            <i className="fas fa-download"></i>
                          )}
                        </div>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
              )
            })}
        </Swiper>
        <div className="swiper_nav">
          <button
            className={`swiper_nav_btn swiper-prev-arrow${category_name}`}
            onClick={handlePrev}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <button
            className={`swiper_nav_btn swiper-next-arrow${category_name}`}
            onClick={handleNext}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </>
  )
}

export default SwiperE2
