import React, { useState, useEffect,useContext } from 'react'
import '../css/style.css';
import '../css/login1.css';
import { NavLink } from 'react-router-dom';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import {subApi} from '../../services/user-api';
import {serviceId,Loader,countryCode,flag} from "../../utils/constant";
import { useTranslation } from "react-i18next";
import Select from "react-select"
import { LanguageContext } from "../../components/LanguageContext"

const Ocm = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [flowLoading, setFlowLoading] = useState(false); 
    const [alreadySub, setAlreadySub] = useState(false);
    const [alias, setAlias] = useState(localStorage.getItem("ugAlias"));   
    const [flow, setFlow] = useState('');   
    const [phone, setPhone] = useState('');
    const [price, setPrice] = useState([0,60,300,80,350]);
    const [planType, setPlanType] = useState(["0","Day","Week","DayOneTime","WeekOneTime"]);   
    const [plan, setPlan] = useState(1);
    const [subOption, setSubOption] = useState(1);
    const [message, setMessage] = useState({message: "",error: false});
    const [otpId, setOtpId] = useState('');
    const [otp, setOtp] = useState('');
    const [ButtonDisabled, setButtonDisabled] = useState(true);
    const { t } = useTranslation("global");
     
 

    useEffect(() => {
        console.log("alias="+alias);
        if (alias) {
            setPhone("0"); 
            setFlowLoading(true); 
            subApi({action:0,ani: phone,serviceId:serviceId,alias:alias}).then((res) => {
                console.log(res);                 
                if(res.data.data!==null && res.data.data!==undefined)
                {
                    setFlowLoading(false);
                    localStorage.setItem("token", res.data.data.accessToken);
                    window.location.href ="/home";
                }
                else
                {
                    setFlowLoading(false);
                    setFlow("SUBOPTION");//
                }
            }).catch((err) => {
                console.log(err);
                setFlowLoading(false);
                setFlow("SUBOPTION");
            });
        }            
    }, []);   

    
    const handleNumberChange = async(event) =>{
        const input = event.target.value;
        if(input.length<11)setPhone(input);
        setButtonDisabled(input.length <8);
    }  
    const handleRadioChange = async(input) =>{
        setPlan(input);
    }  
    const handleSubChange = async(input) =>{
        setSubOption(input);
    }    
    const handleSubOptionClick = async() => {
        if(subOption==2){setPlan(3);}
        else {setPlan(1);}
        setFlow("PACK");
    }
    const cancel = async(input) => {
        setMessage({message: "",error: false});
        setFlow(input);
    }
    const handleNumInputsChange = (numInputs) => {
        setOtp(numInputs);
        setButtonDisabled(numInputs.length !== 4 || isNaN(numInputs));
    }
    const redirectTo = async(input) => {
        if(input=="/home")window.location.href ="/home";
        else navigate(input);
      };

    const handleButtonClick = async() => {
        //console.log(phone);
        setLoading(true);      
        await subApi({action:1,serviceId:serviceId,planId:plan,ani:phone,mode:'WAP',alias:alias}).then((res) => {            
            console.log(res)
            localStorage.setItem("token", res.data.data.accessToken);
            setFlow("SUCCESS");
        }).catch((err) => {
            console.log(err)
            setMessage({message: err.message,error: true})
        }).finally(() => setLoading(false))
      };

           
    const sendOtp = async() => {
        console.log('phone='+phone);
        setMessage({message: "",error: false});
        if(phone.length>=8)
        {
            var nm=phone;if(nm.length<=10)nm=countryCode+nm
            setLoading(true);
            await subApi({action:3,serviceId:serviceId,ani: nm}).then((res) => {   
                if(res.status===200)
                { 
                    if(res.data.responseCode===202) // already subscribed 
                    {
                        localStorage.setItem("token", res.data.data.accessToken);
                        window.location.href ="/home";
                        //setAlreadySub(true);setFlow("SUCCESS");
                    }
                   else if(res.data.responseCode===201) // already exist he 
                    {                        
                        localStorage.setItem("ugAlias", res.data.data);
                        setAlias(res.data.data)
                        setFlow("SUBOPTION");
                    }
                    else
                    {
                        setOtpId(res.data.data); setFlow(true); 
                        setFlow("OTPCONFIRM");                      
                    }
                }                
                else {setMessage({message: res.data.message,error: true});}
                setLoading(false);
            }).catch((err) => {
                console.log(err);
                setMessage({message: err.message, error: true });
                setLoading(false);
            });
        }
        else if(phone.length<=0){setMessage({message: t("portalText.Msg Number"),error: true})}
        else{setMessage({message: t("portalText.Msg Valid Number"),error: true})}
       // navigate('/profile');
      };

      const confirmOtp = async() => {
        setMessage({message: "",error: false});     
        if(otp!=null && otp.length===4)
        {
            var nm=phone;if(nm.length<=8)nm=countryCode+nm
            setLoading(true);
            await subApi({action:4,serviceId:serviceId,ani: nm,otpId:otpId,otp:otp}).then((res) => {                    
                if(res.status===200)
                { 
                    if(res.data.responseCode===202) // already subscribed 
                    {
                        localStorage.setItem("token", res.data.data.accessToken);
                        window.location.href ="/home";
                        //setAlreadySub(true);setFlow("SUCCESS");
                    }
                    else
                    {
                        localStorage.setItem("ugAlias", res.data.data);
                        setAlias(res.data.data)
                        setFlow("SUBOPTION");
                    }                    
                }                
                else {setMessage({message: res.data.message,error: true})}
            }).catch((err) => {
                setMessage({message: err.message, error: true});
            }).finally(() => setLoading(false))
        }
        else{ setMessage({ message:t("portalText.Msg Valid OTP"),error: true });}
      }     


      // Language option //
      
      const { language, changeLanguage } = useContext(LanguageContext); 
      const handleChange = (selectedOption) => {
        changeLanguage(selectedOption.value)
      }
      const languages = [    
        { value: "fr", label: "French" },
        { value: "en", label: "English" },
        //{ value: "pr", label: "Portuguese" },
      ]
      const selectedOption = languages.find((option) => option.value === language)
      const customStyles = {
        control: (provided) => ({
          ...provided,
          backgroundColor: "#d80000",
          fontSize: "18px",
          fontWeight: 700,
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          borderRadius: "10px",
          border: "none",
          outline: "none",
          margin: "0 auto",
          minHeight: "40px",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#fff",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#fff",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#d80000" : "#fff",
          color: state.isSelected ? "#fff" : "#000",
          cursor: "pointer",
        }),
        menu: (provided) => ({
          ...provided,
          borderRadius: "10px",
          marginTop: "5px",
        }),
      }
   

    return (  
        flowLoading? <Loader />
        :<>     
        <div className='home'>
            <div className="maine-div">
                <div className="container-fluid box-wrapper login"> 
                <div className='pt-5'>
                        <div className="row align-items-center">
                            <div className="col-4"></div>
                            <div className="col-4">
                                <div className="logo d-flex">
                                    {/* <img src="/images/uv_games_logo.png" alt="UVGames" /> */}
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="pt-2">
                                        <Select
                                            menuPlacement="top"
                                            value={selectedOption}
                                            options={languages}
                                            onChange={handleChange}
                                            styles={customStyles}
                                            placeholder="Select Language"
                                        />        
                                </div>
                            </div>  
                        </div>
                    </div>              
                    <div className="login-header pb-4">
                        <div className="box-wrapper mx-auto text-center position-relative"> 
                            <span className="modal-level-title game-title">{t("portalText.desc")}</span>
                        </div>
                    </div>
                    <div className="login-info">
                        <div className="login-thumbnail2">
                            <img src="/images/login_img.png" alt="UVGames" />
                        </div>

                        { flow==="PACK" ? (
                        <>
                            {subOption===2?(<>
                                <h3 className="header-title-green mb-3 mt-3 text-center">{t("portalText.One Time Plan")}</h3>                        
                                <div className="text-center radio">
                                    <label>
                                        <input type="radio" id="rd1" value="3" checked={plan === 3} onChange={() => handleRadioChange(3)} />
                                        80U {t("portalText.DayOneTime")}
                                    </label>
                                    <label>
                                        <input type="radio" id="rd2" value="4" checked={plan === 4} onChange={() => handleRadioChange(4)} />
                                        350U {t("portalText.WeekOneTime")}
                                    </label>
                                </div>                               
                            </>
                            ):(<>                            

                                <h3 className="header-title-green mb-3 mt-3 text-center">{t("portalText.Sub Plan")}</h3>                        
                                <div className="text-center radio">
                                    <label>
                                        <input type="radio" id="rd1" value="1" checked={plan === 1} onChange={() => handleRadioChange(1)} />
                                        60U/{t("portalText.Day")}
                                    </label>
                                    <label>
                                        <input type="radio" id="rd2" value="2" checked={plan === 2} onChange={() => handleRadioChange(2)} />
                                        300U/{t("portalText.Week")}
                                    </label>
                                </div>
                            </>
                            )}
                            <div className="text-center phone-info">
                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={handleButtonClick}>{t("portalText.Sub Button")}</button>}
                                <div className="resend_otp"><a className="send_otp_link" onClick={() => {cancel('SUBOPTION')}}> &lt;&lt;&nbsp;{t("portalText.Back")}</a></div>
                            </div>
                        </>                      
                        ): flow === "SUBOPTION" ?(                            
                        <>
                            <h3 className="header-title-red mb-3 mt-3 text-center">{t("portalText.Sub Option")}</h3>                        
                            <div className="text-center radio">
                                <label>
                                    <input type="radio" id="rd1" value="1" checked={subOption === 1} onChange={() => handleSubChange(1)} />
                                    {t("portalText.Subscribe")}
                                </label>
                                <label>
                                    <input type="radio" id="rd2" value="2" checked={subOption === 2} onChange={() => handleSubChange(2)} />
                                    {t("portalText.One Time")}
                                </label>
                            </div>
                            <div className="text-center phone-info">
                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={handleSubOptionClick}>{t("portalText.Submit")}</button>}
                            </div>
                        </>
                        ): flow === "OTPCONFIRM" ?(
                        <>
                             <h3 className="login-title mb-3 mt-3 text-center">{t("portalText.Enter OTP")}</h3>
                             <div className="phone-info">
                                <div className="otp_section">               
                                    <OtpInput
                                        value={otp}
                                        numInputs={4}
                                        renderInput={(props, index) => (
                                            <input   {...props}  type="tel" inputMode="numeric" />
                                        )}
                                        inputStyle="form-control form-otp"
                                        onChange={handleNumInputsChange}
                                    />
                                </div>
                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}
                                {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={confirmOtp} disabled={ButtonDisabled}>{t("portalText.Confirm")}</button>}
                                <div className="resend_otp"><a className="send_otp_link" onClick={() => {cancel('')}}> &lt;&lt;&nbsp;{t("portalText.Back")}</a></div>
                            </div>
                        </>
                        ): flow === "SUCCESS" ?(
                        <>
                            {subOption===2?(
                            <>
                                {alreadySub?
                                <h3 className="login-title-green mb-3 mt-3 text-center">{t("portalText.AlreadySubOneTime")}</h3>
                                : <h3 className="login-title-green mb-3 mt-3 text-center">{t("portalText.SubSuccessOneTime") +" ("+price[plan]+"U "+t("portalText."+planType[plan])+")"}</h3>
                                }
                            </>):(<>
                                {alreadySub?
                                <h3 className="login-title-green mb-3 mt-3 text-center">{t("portalText.AlreadySub")}</h3>
                                : <h3 className="login-title-green mb-3 mt-3 text-center">{t("portalText.SubSuccess")+" ("+price[plan]+"U/"+t("portalText."+planType[plan])+")"}</h3>
                                }
                            </>)}
                            <h3 className="login-title mb-3 mt-3 text-center">{t("portalText.Access Service")}</h3>
                            {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={() => {redirectTo('/home')}}>{t("portalText.Click Here")}</button>}                          
                        
                        </>                        
                        ): (
                            <>                               
                                <h3 className="login-title mb-3 mt-3 text-center">{t("portalText.Welcome")}</h3>                              
                                <div className="phone-input">  
                                    <div className="has-validation input-group">
                                        <div className="dialing-code">
                                            <img src={"https://flagcdn.com/"+flag+".svg"} width="24" alt="Cameroon" />
                                            <span>+{countryCode}</span></div>
                                            <input                                    
                                            className="form-control form-mobile-no" 
                                            type="number" 
                                            inputMode="numeric" 
                                            value={phone}
                                            onChange={handleNumberChange}
                                            placeholder={t("portalText.Enter Number")}
                                        />             
                                        </div>                              
                                    {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p> </div>}                              
                                    {loading ? <Loader />:<button className="btn btn-primary btn-pm-custom" type="button" onClick={sendOtp} disabled={ButtonDisabled}>{t("portalText.Submit")}</button>}
                                </div>
                            </>
                        )}
                    </div>                    
                </div>
            </div>
        </div>
        </>
    )
}

export default Ocm