import { useCallback, useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Autoplay } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import "./css/swiperEl.css"
import { top10Games } from "../utils/topGames"
import { useTranslation } from "react-i18next"

const SwiperTopTen = () => {
  const { t } = useTranslation("global")
  // let { category_name, games } = props.gameData;
  // games = games.slice(0, 10);
  // games =
  const sliderRef = useRef(null)
  const handlePrev = useCallback(() => {
    // if (!sliderRef.current) return;
    // sliderRef.current.swiper.slidePrev();
  }, [])

  const handleNext = useCallback(() => {
    // if (!sliderRef.current) return;
    // sliderRef.current.swiper.slideNext();
  }, [])

  // const handleViewAll = (category_name) => {
  //     navigate(`/allGames/${category_name}`)
  // }

  return (
    <>
      <div className="swiper_element" id="topten_swiper">
        {/* <div className='swiper_top_flex'>
                    <h4>{category_name}</h4>
                    <button onClick={() => handleViewAll(category_name)}>VIEW ALL</button>
                </div> */}
        <Swiper
          ref={sliderRef}
          modules={[Navigation, Autoplay]}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          breakpoints={{
            320: {
              slidesPerView: 1.5,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 1.5,
              spaceBetween: 24,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          navigation={{
            nextEl: `.swiper-next-arrowtopten`,
            prevEl: `.swiper-prev-arrowtopten`,
          }}
        >
          {!!top10Games.length &&
            top10Games.map((current) => {
              return (
                <SwiperSlide key={current?.name}>
                  <div className="swiper_card">
                    {/* <div class="swiper-image"> */}
                    <img src={current?.image} alt={current?.name} />
                    {/* </div> */}
                    <a href={current?.path}>
                      <div className="swiper_card_title">
                        <div className="swiper_card_text">
                          {t(`Top 10.${current.name}`)}
                        </div>
                        <div className="swiper_card_arrow">
                          <i className="fas fa-arrow-right"></i>
                        </div>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
              )
            })}
        </Swiper>
        <div className="swiper_nav">
          <button
            className={`swiper_nav_btn swiper-prev-arrowtopten`}
            onClick={handlePrev}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <button
            className={`swiper_nav_btn swiper-next-arrowtopten`}
            onClick={handleNext}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </>
  )
}

export default SwiperTopTen
