import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import Home from '../components/home';
import Unsub from '../components/unsub';
import AllGames from '../components/allGames';

const ProtectedRoute = () => {

    return (
        <Routes>
            <Route exact path='/' element={<Home/>} /> 
            <Route path='/home' element={<Home/>} />
            <Route path='/allGames/:categoryName?' element={<AllGames/>} />
            <Route path='/unsub' element={<Unsub/>} />
            <Route path='*' element={<Navigate to="/home" />} />
        </Routes>
    )

};

export default ProtectedRoute;