import api from "./interceptor";
import {xAuth,baseUrl,service} from "../utils/constant";
import axios from "axios";


const error={"code":400,"message":"Internet not connected on your device."};
const token = localStorage.getItem('token');
const headers = {
    'Content-Type': 'application/json',
    'x-auth': xAuth
  }

export const subApi = async (userData) => {
    try {
        headers.Authorization='Bearer '+token;   
        let finalUrl=baseUrl;   
        if(service==="ocm")finalUrl=finalUrl+'/user/orange'; 
        else if(service==="mtnz")finalUrl=finalUrl+'/user/mtnz';
        else if(service==="ogb")finalUrl=finalUrl+'/user/ogb';

        const response = await axios.post(`${finalUrl}`, userData,
        {headers:headers});
        return response;
    } catch (e) {        
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};

export const unsubApi = async (userData) => {
    try {
        headers.Authorization='Bearer '+token;   
        let finalUrl=baseUrl;   
        if(service==="ocm")finalUrl=finalUrl+'/user/orange'; 
        else if(service==="mtnz")finalUrl=finalUrl+'/user/mtnz';
        else if(service==="ogb")finalUrl=finalUrl+'/user/ogb';

        const response = await axios.post(`${finalUrl}`, userData,
        {headers:headers});
        return response;
    } catch (e) {        
        if(e.response!=null && e.response!=undefined) throw setResponse(e);
        else throw error;
    }
};



export const getRanking = async () => {
    try {
        headers.Authorization='Bearer '+token;
        const response = await axios.post(`${baseUrl}/user/list_user_time`,'',
            { headers: headers})
        return response;
    } catch (e) {
        throw error;
    }
}
function setResponse(e)
{   
    //console.log("e="+JSON.stringify(e.response));
    if(e.response.status==401){
        localStorage.clear();
        window.location.href ="/";
    }
    else {return e.response.data;}
}
