import React, { createContext, useEffect, useState } from "react"
import i18n from "../i18n"
import {lang} from "../utils/constant"

const LanguageContext = createContext()

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(lang)

  // getting lang from localStorage to persist across all pages
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language") || lang // default eng
    setLanguage(storedLanguage)
    i18n.changeLanguage(storedLanguage)
  }, [])

  const changeLanguage = (lang) => {
    setLanguage(lang)
    localStorage.setItem("language", lang)
    i18n.changeLanguage(lang)
  }

  return (
    <LanguageContext.Provider value={{ changeLanguage, language }}>
      {children}
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider }
