import React, { useState, useContext } from "react"
import "../css/navbar.css"
import Winners from "../winners"
import Select from "react-select"
import { useNavigate } from "react-router-dom"
import { LanguageContext } from "../LanguageContext"
import { useTranslation } from "react-i18next";
import {service} from "../../utils/constant";

const Navbar = () => {
  const { t } = useTranslation("global")
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { language, changeLanguage } = useContext(LanguageContext)
  const navigate = useNavigate()

  // custom style for lang button
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#d80000",
      fontSize: "18px",
      fontWeight: 700,
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "10px",
      border: "none",
      outline: "none",
      margin: "0 auto",
      minHeight: "40px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#d80000" : "#fff",
      color: state.isSelected ? "#fff" : "#000",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "10px",
      marginTop: "5px",
    }),
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const onLogout = () => {
    //localStorage.clear()
    window.location.href = "/unsub"
  }

  const handleChange = (selectedOption) => {
    changeLanguage(selectedOption.value)
  }

  let languages = [];
      if(service==="ogb")
        {
            languages=[    
            { value: "pr", label: "Portuguese" },
            { value: "en", label: "English" }
            ]
        }
        else
        {
            languages=[    
                { value: "fr", label: "French" },
                { value: "en", label: "English" }
                ]

        }
  const selectedOption = languages.find((option) => option.value === language)

  const token = localStorage.getItem("token")
  
  return (
    <>
      <nav className="navigation">
        <a href="/" className="brand-name">
          {/* <img src="/images/uv_games_logo.png" alt="UV Games Logo" /> */}
        </a>
        <div>
          {/* {!!token && ( */}
          <div className="mobile-icons"> 
             {!!token && (
                <button
                  className="mobile-home-button home_button"
                  onClick={onLogout}
                >
                  <i className="fas fa-house"></i>
                </button>
              )}           
              <button
                className="mobile-android-button winners_button"
                onClick={() => navigate("/allGames/Android")}
              >
                <i className="fa fa-android"></i>
              </button>

              <button
                className="mobile-winner-button winners_button"
                onClick={openModal}
              >
                <i className="fa-solid fa-trophy"></i>
              </button>
             
          </div>

          {/* )} */}
          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded)
            }}
          >
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>

        <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }
        >
          <ul>
            <li>
              <a href="/allGames/Sports">{t("portalText.sport")}</a>
            </li>
            <li>
              <a href="/allGames/Funny">{t("portalText.funny")}</a>
            </li>
            <li>
              <a href="/allGames/Action">{t("portalText.action")}</a>
            </li>
            <li>
              <a href="/allGames/Adventure">{t("portalText.adventure")}</a>
            </li>
            <li>
              <a href="/allGames/Racing">{t("portalText.racing")}</a>
            </li>
            <li>
              <a href="/allGames/Kids">{t("portalText.kids")}</a>
            </li>
            <li>
              <a href="/allGames/Sports">{t("portalText.puzzle")}</a>
            </li>
            <li className="mobile-category">
              <a href="/allGames/Android">{t("portalText.android")}</a>
            </li>
            {/* <li>
                <a href="/allGames/Sports">Arcade</a>
            </li> */}

            <>
            {!!token && (
              <li>
                  <button
                    className="logout_button tabletdesktop-logout-button"
                    onClick={onLogout}
                  >
                    <i className="fas fa-house"></i>
                  </button>
              </li>
              )}
              <li>
                {/* <a href="/allGames/Android" className="brand-name"> */}
                <button
                  className="winners_button tabletdesktop-winner-button"
                  onClick={() => navigate("/allGames/Android")}
                >
                  <i className="fa fa-android"></i> {t("portalText.android")}
                </button>
                {/* </a> */}
              </li>
              <li>
                <button
                  className="winners_button tabletdesktop-winner-button"
                  onClick={openModal}
                >
                  <i className="fa-solid fa-trophy"></i>{" "}
                  {t("portalText.winners")}
                </button>
              </li>
              <Select
                value={selectedOption}
                options={languages}
                onChange={handleChange}
                styles={customStyles}
                placeholder="Select Language"
              />

             
            </>
          </ul>
        </div>
      </nav>
      {/* {!!token && <Winners isOpen={isOpen} closeModal={closeModal} />} */}
      <Winners isOpen={isOpen} closeModal={closeModal} />
    </>
  )
}

export default Navbar
